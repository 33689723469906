import React from 'react';
import IndexLayout from '../layouts';
import { Helmet } from 'react-helmet';
import SiteNav from '../components/header/SiteNav';
import { Wrapper } from '../components/Wrapper';
import { Footer } from '../components/Footer';
import { css } from '@emotion/core';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  @media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  }
`;

const Support: React.FC = () => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Support</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <h1 style={{ textAlign: 'center' }}>Support</h1>
              <p style={{ textAlign: 'center' }}>Coming soon!</p>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default Support;
